import {useEffect, useState} from 'react'

const useScrollBeforeComponent = (elementId: string): boolean => {
  const [isScrollYBeforeComponent, setScrollBeforeComponent] = useState<boolean>(false)
  const [scrollY, setScrollY] = useState<number>(0)

  const handleScroll = () =>
    setScrollY(document?.getElementById(elementId)?.getBoundingClientRect()?.top || 0)

  useEffect(() => {
    setScrollBeforeComponent(scrollY - window.innerHeight > 0)
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [scrollY])
  return isScrollYBeforeComponent
}

export default useScrollBeforeComponent
