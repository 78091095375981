import {useEffect, useState} from 'react'

const useScrollAfterComponent = (elementId: string): boolean => {
  const [isScrollYAfterComponent, setScrollAfterComponent] = useState<boolean>(false)
  const [scrollY, setScrollY] = useState<number>(0)

  const handleScroll = () => setScrollY(window.scrollY)

  useEffect(() => {
    setScrollAfterComponent(
      (document?.getElementById(elementId)?.getBoundingClientRect()?.bottom || 0) < 0,
    )
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [scrollY])
  return isScrollYAfterComponent
}

export default useScrollAfterComponent
