import {useEffect, useState} from 'react'

const useScrollingDown = (): boolean => {
  const [isScrollingDown, setScrollDown] = useState<boolean>(false)
  const [scrollTop, setScrollTop] = useState(0)

  const handleScrollDown = () => {
    if (scrollTop < document.documentElement.scrollTop && !isScrollingDown) {
      setScrollDown(true)
    } else if (scrollTop >= document.documentElement.scrollTop && isScrollingDown) {
      setScrollDown(false)
    }
    setScrollTop(document.documentElement.scrollTop)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScrollDown)
    return () => window.removeEventListener('scroll', handleScrollDown)
  }, [isScrollingDown, scrollTop])
  return isScrollingDown
}

export default useScrollingDown
