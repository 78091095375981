import {SkeletonTheme} from 'react-loading-skeleton'
import {BrowserRouter, Route, Routes} from 'react-router-dom'

import Footer from './components/Footer/Footer'
import Header from './components/Header/Header'
import {ScrollToTop} from './components/ScrollToTop/ScrollToTop'
import {PATHS} from './constants/paths'
import Home from './pages/home/Home'

import styles from './App.module.scss'

function App() {
  return (
    <BrowserRouter>
      <SkeletonTheme baseColor="#e6e8ec" highlightColor="#f4f5f6">
        <ScrollToTop>
          <Header />
          <div className={styles.content}>
            <Routes>
              <Route index path={PATHS.HOME} element={<Home />} />
              <Route path="*" element={<Home />} />
            </Routes>
          </div>
          <Footer />
        </ScrollToTop>
      </SkeletonTheme>
    </BrowserRouter>
  )
}

export default App
