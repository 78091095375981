import {useEffect, useState} from 'react'

const useScrollPosition = (elementId: string, position: 'top' | 'bottom', offsetY = 0): boolean => {
  const [isRequiredPosition, setRequiredPosition] = useState<boolean>(false)
  const [scrollY, setScrollY] = useState<number>(0)

  const handleScroll = () => setScrollY(window.scrollY)

  useEffect(() => {
    if (position === 'top') {
      setRequiredPosition(
        (document?.getElementById(elementId)?.getBoundingClientRect()?.top || 0) < 0 + offsetY,
      )
    } else {
      setRequiredPosition(
        window.innerHeight >=
          (document?.getElementById(elementId)?.getBoundingClientRect()?.bottom || 0),
      )
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [scrollY])
  return isRequiredPosition
}

export default useScrollPosition
