import {useMemo} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {ReactComponent as Close} from '../../../assets/icons/close.svg'
import {PATHS} from '../../../constants/paths'

import styles from './Menu.module.scss'

interface IMenuOption {
  translationKey: string
  action: () => void
}

interface IMenuProps extends WithTranslation {
  onChooseAction: () => void
}
const Menu = ({t, onChooseAction}: IMenuProps) => {
  const navigate = useNavigate()
  const menuOptions: IMenuOption[] = useMemo(
    () => [
      {
        translationKey: 'myWallet',
        action: () => {
          onChooseAction()
          navigate(PATHS.HOME)
        },
      },
      {
        translationKey: 'profile',
        action: () => {
          onChooseAction()
          navigate(PATHS.HOME)
        },
      },
      {
        translationKey: 'recentTransactions',
        action: () => {
          onChooseAction()
          navigate(PATHS.HOME)
        },
      },
      {
        translationKey: 'help',
        action: () => {},
      },
      {
        translationKey: 'singOff',
        action: () => {
          onChooseAction()
        },
      },
    ],
    [t],
  )

  return (
    <div className={styles.menu}>
      <Close className={styles.close} onClick={onChooseAction} />
      {menuOptions.map(option => (
        <div key={option.translationKey} className={styles.option} onClick={option.action}>
          {t(`header.menu.${option.translationKey}`)}
        </div>
      ))}
    </div>
  )
}

export default withTranslation()(Menu)
